import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faShare } from '@fortawesome/free-solid-svg-icons';
import './Reviews.css';

const Reviews = () => {
  const reviews = [
    {
      name: "Mrs. Saha", 
      role: "School Headmaster",
      review: "A well-thought-out and age-appropriate educational kit for children, CurioKAARDS effectively minimizes screen time and reduces distractions",
      rating: 5
    },
    {
      name: "Mr. Suresh", 
      role: "Parent",
      review: "Based on my son's experience with CurioKAARDS, they are an excellent learning tool that truly excites him by integrating play with learning. In fact, their simplicity and child-friendly design even keep my 2-year-old engaged!",
      rating: 5
    },
    {
      name: "Mrs. Priya", 
      role: "Parent",
      review: "CurioKAARDS has truly been an incredible innovation by the company! My 5-year-old loves it and finds it highly engaging. It not only sparks her thinking skills but also provides a fantastic alternative to screen time. It's a wonderful way to keep children engaged in brain-boosting activities. Kudos to the entire team for such a brilliant creation! ",
      rating: 5
    },
    {
      name: "Mrs. Annapoorna",
      role: "School Headmaster",
      review: "CurioKAARDS feature convenient designs that are perfect for our Class 1 students and serve as an effective educational resource for young learners",
      rating: 5
    },

  ];

  const renderStars = (rating) => {
    return [...Array(rating)].map((_, i) => (
      <FontAwesomeIcon key={i} icon={faStar} className="star-icon" />
    ));
  };

  return (
    <div className="reviews-section">
      <div className="title">What People Say About Us</div>
      <div className="reviews-container">
        {reviews.map((review, index) => (
          <div key={index} className="review-card">
            <div className="review-content">
              <div className="rating">{renderStars(review.rating)}</div>
              <p className="review-text">"{review.review}"</p>
              <div className="reviewer-info">
                <h3>{review.name}</h3>
                <p>{review.role}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="submit-review-section">
      <a href="https://docs.google.com/forms/d/e/1FAIpQLSf6PSfo2uHy_tpK6ci0JrFgeqYrhL_9hQXbD6psGgExHciP8w/viewform?usp=sf_link"
      className="submit-review-btn" target="_blank" rel="noopener noreferrer">
      <FontAwesomeIcon icon={faShare} className="share-icon" /> Share Your Experience
      </a>
      </div>
    </div>
  );
};

export default Reviews;
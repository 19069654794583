import React, { useEffect, useState } from 'react';
import Home from '../Pages/Home';
import AboutUs from '../Pages/AboutUs';
import Product from '../Pages/Product';
import Teams from '../Pages/Teams';
import Supporters from '../Pages/Supporters';
import Reviews from '../Pages/Reviews';

const MainPage = () => {
  const [activeSection, setActiveSection] = useState('home');

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll('section');
      
      sections.forEach(section => {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.clientHeight;
        if (window.scrollY >= (sectionTop - sectionHeight/3)) {
          setActiveSection(section.id);
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div>
      <section id="home">
        <Home />
      </section>
      <section id="about">
        <AboutUs />
      </section>
      <section id="products">
        <Product />
      </section>
      <section id="reviews">
      <Reviews />
      </section>
      <section id="team">
        <Teams />
      </section>
      <section id="supporters">
        <Supporters />
      </section>
    </div>
  );
};

export default MainPage;
import React, { useState, useEffect } from "react"
import "./Product.css"
import p1 from "../Images/Products/p1.jpeg"
import p2 from "../Images/Products/p2.jpeg"
import p3 from "../Images/Products/p3.jpeg"
import p4 from "../Images/Products/p4.jpeg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons"

const Product = () => {

  const images = [p1, p2, p3, p4]
  const [currentImage, setCurrentImage] = useState(0)

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentImage((prev) => (prev === images.length - 1 ? 0 : prev + 1))
    }, 4000)

    return () => clearInterval(timer)
  }, [currentImage])

  const nextImage = () => {
    setCurrentImage((prev) => (prev === images.length - 1 ? 0 : prev + 1))
  }

  const prevImage = () => {
    setCurrentImage((prev) => (prev === 0 ? images.length - 1 : prev - 1))
  }

  return (
    <div className="product-sec">
      <div className="title">Our Products</div>
      <div className="curiokaards-container">
        <div className="image-carousel">
          <button className="carousel-btn prev" onClick={prevImage}>
            <FontAwesomeIcon icon={faChevronLeft} size="2x"/>
          </button>
          <img src={images[currentImage]} alt="CurioKAARDS" />
          <button className="carousel-btn next" onClick={nextImage}>
            <FontAwesomeIcon icon={faChevronRight} size="2x"/>
          </button>
          <div className="carousel-dots">
            {images.map((_, index) => (
              <span
                key={index}
                className={`dot ${index === currentImage ? "active" : ""}`}
                onClick={() => setCurrentImage(index)}
              />
            ))}
          </div>
        </div>
        <div className="product-info">
          <h2>CurioKAARDS</h2>
          <p>
            CurioKAARDS™ are engaging educational kits designed
            to spark curiosity in children. Each pack features
            curriculum-aligned gamified flashcards, where children
            earn points for correct answers and bonus points for
            exceptional responses. Perfect for individual or group
            activities, they can be used in classrooms or at home
            with parents. The kit includes a comprehensive
            instruction manual and a scorecard to track progress.
            The kit is designed for children aged 3 to 7 years,
            covering pre-primary to class 1 levels. Teachers and parents can adjust 
            the complexity level to suit children at different developmental stages.
          </p>
          <div className="price">Price Per Kit : ₹150</div>
          <a 
            href="https://docs.google.com/forms/d/e/1FAIpQLSe-Yx3JcjL2nNI6vGDPa9oGa4KgIrcea1_m3Z1l_5COdn_Ryg/viewform?usp=sf_link"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="order-button">Place Order</button>
          </a>
        </div>
      </div>
    </div>
  )
}

export default Product
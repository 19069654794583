import React from "react"
import IIM from "../Images/Sponsors/IIM.png"
import IIM_Team from "../Images/Sponsors/Bangalore.jpeg"
import "./Supporters.css"

const Supporters= () => {
  return (
    <>
      <div className="aboutMainSec">
      <div className="title">Our Supporters</div>
        <div className="supporters-sec">
          <img src={IIM} alt="IIM Logo" />
          <img src={IIM_Team} alt="IIM Bangalore Team" />
        </div>
      </div>
    </>
  )
}

export default Supporters

import React from "react"
import "./Teams.css"
import TeamCard from "../Components/TeamCard"
import Team1 from "../Images/Teams/Team1.png"
import Team2 from "../Images/Teams/Team2.png"
import Team3 from "../Images/Teams/Team3.png"
import Team4 from "../Images/Teams/Team4.png"
import Team5 from "../Images/Teams/Team5.png"

const Teams = () => {
  const teamMembers = [
    {
      name: "Dr. Muthukumar Chockalingam",
      role: "Founder, Director, and Chief Innovation Officer (CIO)",
      image: `${Team1}`,
      bio: "Leads educational innovation, product development, and technology integration to create engaging, impactful learning tools for children.",
    },
    {
      name: "Darshan Reddy G",
      role: "Co-Founder, Director, and Chief Financial Officer (CFO)",
      image: `${Team2}`,
      bio: "Manages financial strategy, investments, and operational growth to scale the business while ensuring sustainability and profitability.",
    },
    {
      name: "Dr. Jagan Mohan B",
      role: "Chief Product Officer (CPO)",
      image: `${Team3}`,
      bio: "Designs products and user experiences focused on children, ensuring accessibility, engagement, and educational value across all products.",
    },
    {
      name: "Dr. Gopalakrishnan G",
      role: "Chief Operating Officer (COO)",
      image: `${Team4}`,
      bio: "Oversees operations, optimizes processes, builds teams, and creates sales strategies to support business growth and operational efficiency.",
    },
    {
      name: "Rao Rakshith Umesh",
      role: "Chief Technology Officer (CTO)",
      image: `${Team5}`,
      bio: "Leads the development of technology solutions and innovations, ensuring seamless integration of tech to enhance learning experiences for children.",
    },
  ]

  const interns = [
    {
      name: "Dawn Mathew Mathew",
      role: "Product Designer",
      duration: "3 months",
      year: "2024-25"
    },
    {
      name: "Rakesh Kumar",
      role: "Software Development Engineer",
      duration: "3 months",
      year: "2023-24"
    },
    {
      name: "Karthik B",
      role: "Product Designer",
      duration: "3 months",
      year: "2024-25"
    },
    {
      name: "Lasya Kuna",
      role: "Software Development Engineer",
      duration: "3 months",
      year: "2024-25"
    },
    {
      name: "Saket Thapa",
      role: "Graphic Designer",
      duration: "3 months",
      year: "2023-24"
    },
    {
      name: "Rahul Deora",
      role: "Marketing Executive",
      duration: "3 months",
      year: "2023-24"
    },
    {
      name: "Vedant Sandeep Dabhade",
      role: "Content Developer",
      duration: "3 months",
      year: "2023-24"
    },
    {
      name: "Vineet Kumar Kushwaha",
      role: "Graphic Designer",
      duration: "3 months",
      year: "2023-24"
    },
    {
      name: "Thushar M",
      role: "Content Developer",
      duration: "3 months",
      year: "2023-24"
    },
    {
      name: "Md Arham",
      role: "Content Developer",
      duration: "3 months",
      year: "2023-24"
    },
    {
      name: "Nidhin Manoharan",
      role: "Content Developer",
      duration: "3 months",
      year: "2023-24"
    }
  ]

  return (
    <>

    {/* Team section */}
      <div className="teamSection">
        <div className="title">Meet Our Team</div>
        <div className="app">
          {teamMembers.map((member, index) => (
            <TeamCard key={index} {...member} />
          ))}
        </div>
      </div>

      {/* Consultant Section */}
      <div className="consultantSection">
      <div className="title">Our Consultants</div>
      <div className="consultant-grid">
        <div className="grid-header">
          <div className="header-item">Consultant Name</div>
          <div className="header-item">Area of Expertise</div>
          <div className="header-item">Duration of Association</div>
        </div>
        <div className="grid-row">
          <div className="row-item">Yimkumer Pongen</div>
          <div className="row-item">Software Technologies, Application Development</div>
          <div className="row-item">2024-25</div>
        </div>
      </div>
    </div>

    {/* Interns Section */}
    <div className="internSection">
  <div className="title">Our Valuable Interns</div>
  <div className="intern-grid">
    <div className="grid-header">
      <div className="header-item">Intern Name</div>
      <div className="header-item">Role</div>
      <div className="header-item">Duration</div>
      <div className="header-item">Year</div>
    </div>
    {interns.map((intern, index) => (
      <div className="grid-row" key={index}>
        <div className="row-item">{intern.name}</div>
        <div className="row-item">{intern.role}</div>
        <div className="row-item">{intern.duration}</div>
        <div className="row-item">{intern.year}</div>
      </div>
    ))}
  </div>
</div>
    </>
  )
}

export default Teams

import { createBrowserRouter } from "react-router-dom"
import Layout from "../Layout/Layout"
import ErrorPage from "../Pages/ErrorPage"
import MainPage from "../Components/MainPage"
import ContactUs from "../Pages/ContactUs"

// Route constants
export const ROOT = "/"
export const CONTACT = "/contact"

// Router configuration
export const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: ROOT,
        element: <MainPage />,
      },
      {
        path: CONTACT,
        element: <ContactUs />,
      }
    ],
  },
])
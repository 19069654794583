import React, { useEffect } from "react"
import AOS from "aos"
import "aos/dist/aos.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck } from "@fortawesome/free-solid-svg-icons"
import "./AboutUs.css"
import image3 from "../Images/Assets/about.jpeg"

export const AboutUs = () => {
  useEffect(() => {
    AOS.init({})
  }, [])

  return (
    <section className="aboutus">
      <div className="title">About Us</div>
      <div className="aboutus-sec">
        <div className="about-img"
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1200"
        >
          <img src={image3} alt="About Us" className="animation"/>
        </div>
        <div className="aboutMainHeader">
          <p className="aboutMainPara">
            At CURIOSENSE INNOVATIONS PVT LTD, we believe in the transformative power
            of curiosity and play-based learning to shape resilient minds. Our mission
            is rooted in fostering not just academic growth, but also nurturing the
            mental well-being of children.
          </p>
          
          <h2 className="aboutHeader">Why Choose Us?</h2>
          <div className="features-container">
            <div className="feature-item">
              <FontAwesomeIcon icon={faCheck} className="correctSign" />
              <span>Our Approach: Curiosity Unleashed</span>
            </div>
            <div className="feature-item">
              <FontAwesomeIcon icon={faCheck} className="correctSign" />
              <span>Mind Matters: Prioritizing Mental Health</span>
            </div>
            <div className="feature-item">
              <FontAwesomeIcon icon={faCheck} className="correctSign" />
              <span>Innovative Tools for Tomorrow's Skills</span>
            </div>
            <div className="feature-item">
              <FontAwesomeIcon icon={faCheck} className="correctSign" />
              <span>Skills for Tomorrow: Empowering Futures</span>
            </div>
            <div className="feature-item">
              <FontAwesomeIcon icon={faCheck} className="correctSign" />
              <span>Transforming the way of learning</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AboutUs
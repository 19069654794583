import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import { RouterProvider } from "react-router-dom"
import { router } from "./Routes/Routes"

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  
      <React.StrictMode>
        <RouterProvider router={router} />
      </React.StrictMode>
  
)

import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Assets from "../Images/index";
import AOS from "aos";
import "aos/dist/aos.css";
import "./Home.css";

const Home = () => {
  const companyName = "Curiosense Innovations";
  const AboutCompany =
    "Curiosense Innovations prioritizes a play-centric, curiosity-driven approach for resilient minds. Emphasizing holistic development and mental well-being, we collaborate with parents, teachers, experts and schools to form a comprehensive learning ecosystem. With innovative tools, we prepare children not just for today but also for future opportunities, focusing on skill development and lifelong success.";

  useEffect(() => {
    AOS.init({});
  }, []);

  return (
    <>
      <div className="home-sec">
        <div className="home-cnt">
          <h2>
            Welcome To <br /> <span className="subHeading">{companyName}</span>
          </h2>
          <p>{AboutCompany}</p>
        </div>
        <div
          className="home-img"
          data-aos="fade-zoom-in"
          data-aos-easing="ease-in-back"
          data-aos-delay="100"
          data-aos-offset="1"
        >
          <img className="animation" src={Assets?.home} />
        </div>
      </div>

      <div className="latest-news-sec">
        <h2 className="latest-news-title">Latest news</h2>
        <div className="latest-news-items">
          <div className="latest-news-item">
            <img src={Assets?.n1} alt="News image 1" />
            <div className="latest-news-details">
              <h3>CurioKAARDS : A fun way to Learn and Grow</h3>
              <p>09 April 2024 | News release</p>
            </div>
          </div>
          <div className="latest-news-item">
            <img src={Assets?.n2} alt="News image 2" />
            <div className="latest-news-details">
              <h3>Fun Education : Bringing Smiles to all ages!</h3>
              <p>19 May 2024 | News release</p>
            </div>
          </div>
          <div className="latest-news-item">
            <img src={Assets?.n3} alt="News image 3" />
            <div className="latest-news-details">
              <h3>Is your little one eager to learn?</h3>
              <p>29 June 2024 | News release</p>
            </div>
          </div>
          <div className="latest-news-item">
            <img src={Assets?.n4} alt="News image 4" />
            <div className="latest-news-details">
              <h3>See how many children prefer this way of learning!</h3>
              <p>19 November 2024 | News release</p>
            </div>
          </div>
        </div>

      </div>
    </>
  );
};

export default Home;
import React, { useState } from "react";
import "./NavBar.css";
import Logo from "../Images/new-logo.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faHome,
  faInfoCircle,
  faBoxOpen,
  faUsers,
  faHandshake,
  faComments
} from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useLocation } from 'react-router-dom';
import { ROOT } from '../Routes/Routes';

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const scrollToSection = (sectionId) => {
    // Check if we're on the main page
    if (location.pathname !== '/') {
      // If not, navigate to main page with section hash
      navigate('/', { state: { scrollTo: sectionId } });
      return;
    }

    const section = document.getElementById(sectionId);
    if (section) {
      const navbarHeight = document.querySelector('.navbar').offsetHeight;
      const offset = 30;
      const targetPosition = section.offsetTop - navbarHeight - offset;
      
      window.scrollTo({
        top: targetPosition,
        behavior: "smooth"
      });
    }
    setIsOpen(false);
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <div className="navbar-logo">
          <img 
            src={Logo} 
            alt="Logo" 
            onClick={() => {
              navigate(ROOT);
              window.scrollTo(0, 0);
            }} 
          />
        </div>
        <div className="navbar-right">
          <a href="https://docs.google.com/forms/d/e/1FAIpQLSdwM0Ap-fPL_nbJyKr6C7-uh1JYjFbGQHOJNiLF_onsjrZRIw/viewform" className="donate-button"   target="_blank" rel="noopener noreferrer">
            Sponsor
          </a>
          <div className="navbar-toggle" onClick={() => setIsOpen(!isOpen)}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
      <div className={`navbar-links ${isOpen ? "active" : ""}`}>
        <a href="#home" onClick={() => scrollToSection("home")}>
          <FontAwesomeIcon icon={faHome} className="nav-icon" /> Home
        </a>
        <a href="#about" onClick={() => scrollToSection("about")}>
          <FontAwesomeIcon icon={faInfoCircle} className="nav-icon" /> About
        </a>
        <a href="#products" onClick={() => scrollToSection("products")}>
          <FontAwesomeIcon icon={faBoxOpen} className="nav-icon" /> Products
        </a>
        <a href="#reviews" onClick={() => scrollToSection("reviews")}>
        <FontAwesomeIcon icon={faComments} className="nav-icon" /> Reviews
        </a>
        <a href="#team" onClick={() => scrollToSection("team")}>
          <FontAwesomeIcon icon={faUsers} className="nav-icon" /> Team
        </a>
        <a href="#supporters" onClick={() => scrollToSection("supporters")}>
          <FontAwesomeIcon icon={faHandshake} className="nav-icon" /> Our Supporters
        </a>
      </div>
    </nav>
  );
};

export default NavBar;